.MuiCardHeader-title {
  font-size: medium !important;
  font-weight: 600 !important;
}
.MuiCardHeader-subheader {
  font-size: small !important;
}
.css-aqqxug-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper:before {
  top: 40% !important;
  right: -5px !important;
}
.css-1qaznj3-MuiCardContent-root:last-child {
  padding-bottom: 0 !important;
}
/* .css-jzk4qw-MuiPaper-root-MuiAppBar-root,
.css-12i7wg6-MuiPaper-root-MuiDrawer-paper,
.css-118zhtq-MuiButtonBase-root-MuiFab-root-MuiSpeedDial-fab,
.css-hup40y-MuiSpeedDial-root {
  z-index: 1000 !important;
} */
header,
.MuiDrawer-paperAnchorLeft,
.MuiSpeedDial-fab,
.MuiSpeedDial-directionLeft {
  z-index: 1000 !important;
}
.Appointment-shadedAppointment {
  opacity: 0.8;
}
.alert {
  position: absolute;
  left: 50%;
  top: 50px;
  transform: translateX(-50);
  z-index: 99999;
}
.ql-toolbar.ql-snow {
  border: none;
  border-bottom: 1px solid #ccc;
}
.ql-container.ql-snow {
  border: none;
}
.datePicker {
  /* h-10 w-full min-w-[250px] rounded-md border border-gray-200 shadow-sm text-sm */
  height: 40px;
  width: 100%;
  min-width: 250px;
  border-radius: 8px;
  border: 1px solid rgba(182, 179, 179, 0.955);
  font-size: 16px;
  text-align: center;
}
.db-list {
  /* padding-left: 0 !important;
  padding-right: 0 !important; */
  list-style: none !important;
}
.db-list > .MuiListItemAvatar-root {
  min-width: 45px !important;
}
.MuiAccordionSummary-content.Mui-expanded {
  margin: 12px !important;
}
.MuiAccordionDetails-root {
  padding: 0 !important;
}
.react-datepicker-wrapper {
  max-width: max-content !important;
}
.react-datepicker__input-container input,
.react-datepicker-ignore-onclickoutside {
  height: 30px;
}

